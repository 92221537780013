<template>
  <div class='box'>
    <van-icon name="shop-o" />
    门店列表
  </div>
  <div class="choice"  style="margin-bottom: 50px;">
    <van-list :finished="true" finished-text="没有更多了" >
        <div class="card">
          <div v-for="(item,index) in goodsList" :key="index" @click="details(item.id)" style="margin-top:10px">
            <van-card
              :thumb="imageUrl+item.image"
              :desc="item.address">
              <template #title>
                <div class="card-title van-multi-ellipsis--l2">{{item.name}}</div>
              </template>
              <template #tags>
                <van-tag plain type="danger">{{item.trade_name}}</van-tag>
              </template>
              <template #price>
                <div class="card-price">
                  <span>账号：{{item.code}}</span>
                </div>
              </template>
            </van-card>
          </div>
        </div>
      </van-list>
    </div>
</template>

<script>
import { storeList } from '@/api/merchant'

	export default {
		data() {
			return {
        imageUrl:"https://shop2.esw235.cn/",
        page: 1,
        limit:10,
				upFinished: false, // 上拉加载完毕
      	goodsList: [], 
			}
    },
    mounted() {
      this.artiList()
    },
    
		methods: {
      artiList() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
        params.page = this.page
        params.limit = this.limit
				storeList(params).then((res) => {
					console.log(res)
					this.goodsList = res.data.data
					console.log(this.goodsList)
					if (this.goodsList.length === 0) {
						// 加载结束
						this.upFinished = true
						return
					}
					if (this.goodsList.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
				})
      },
      details(val){
        console.log(val)
        this.$router.push({path:'/store/addStore',query:{id: val}})
      }
		},
		
	}
</script>

<style lang="less" scoped>
.box{
  line-height: 7rem;
  font-size: 16px;
  padding-left: 1.4rem;
  color: #323233;
  background: #fff;
}
.choice{
  min-height: calc(100vh);
  ::v-deep .van-cell__value{
    color: #df2020;
  }
  ::v-deep .van-cell-group__title {
    color: #df2020;
}
}
.shuju{
  text-align: center;
  background:#fff;
  font-size: 14px;
  height: 6rem;
  line-height: 3rem;
  color: #df2020;
  
}

</style>
